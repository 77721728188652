import { useDispatch } from "react-redux";
import { updateCurrentModel } from "../../../store/PromptEngingeeringSlice/PromptEngineeringSlice";
import useDeprecatedModel from "../hooks/useDeprecatedModel";
import { useSearchParams } from "react-router-dom";

export function ModelListItem(model) {
  const [searchParams] = useSearchParams();
  const deprecatedModel = useDeprecatedModel(model);
  const dispatch = useDispatch();
  const { displayName } = model;
  const handleModelListItemClick = () => {
    dispatch(updateCurrentModel(model));
  };

  if (searchParams.get("pro") === null && deprecatedModel) return null;

  return (
    <button
      type="button"
      className="list-group-item list-group-item-action"
      onClick={handleModelListItemClick}
    >
      {displayName}{" "}
      {deprecatedModel && <strong className="text-warning">D</strong>}
    </button>
  );
}
