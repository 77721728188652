// @todo there might be potential run time issue
export const dutyCellPendingReducer = (state, action) => {
  console.log("action.meta.arg", action.meta.arg);
  state.dutyCell[action.meta.arg["productDeviceName"]].status = "pending";
};

export const dutyCellFulfilledReducer = (state, action) => {
  console.log("dutyCellFulfilledReducer action", action);
  const { error } = action;
  const { responce } = action.payload;
  if (error) {
    console.error("dutyCellFulfilledReducer error", error);
    state.dutyCell[action.meta.arg["productDeviceName"]].status = "failed";
    return;
  }
  state.dutyCell[action.meta.arg["productDeviceName"]].status = "success";
  state.dutyCell[action.meta.arg["productDeviceName"]].result =
    responce.candidates[0].content.parts[0].text;
};

export const dutyCellRejectedReducer = (state, action) => {
  state.error = action.payload;
  // state.dutyCell[action.meta.arg["productDeviceName"]].status = "failed";
  console.error("dutyCell.rejected", action);
};
