import chat from "./Chat";
import TextCompletions from "./Completions";

class OpenAIService {
  constructor() {
    this.chat = chat;
    this.completions = new TextCompletions();
  }

  static async run({ prompt, messages, model }) {
    if (messages) {
      const rsp = this.chat.completions.create({ messages, model });
      return rsp;
    } else if (prompt) {
      const rsp = this.completions.create({ prompt, model });
      return rsp;
    } else {
      throw new Error("prompt or messages should be provided");
    }
  }
}

export default OpenAIService;
