import { useDispatch, useSelector } from "react-redux";
import FileAttachment from "../../../../components/forms/FileAttachment";
import { useEffect, useState } from "react";
import { MarkdownTableWrapper } from "../../TradingCompliance";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";
import LegalComplianceContent from "./LegalComplianceContent";
import CustomsTudyEstimationTable from "../CustomsDutyEstimation/CustomsDutyEstimationTable";
import { legalComplianceTempThunk } from "../../../../store/TradinAIThunks";

export default function LegalComplianceSection() {
  const dispatch = useDispatch();
  const {
    attachment,
    fid,
    status: fileStatus,
    csvResponce,
    manufactureResponce,
    aiProcessManufacturesEntityList,
    aiProcessProductsDuty,
  } = useSelector((state) => state.file);
  const { legalCompliance, harmonizedCode } = useSelector(
    (state) => state.tradinAI
  );

  const { result, status: legalComplianceStatus } = legalCompliance;
  const [message, setMessage] = useState("");

  // const prompt = `
  //   (The US has banned the sale and import of new communications equipment from
  //     five Chinese companies, including Huawei. This includes the Huawei Mate 30 Pro.
  //     The ban is due to concerns that Huawei's equipment could be used to spy on
  //     the US and other companies. The ban also prevents Huawei from working with
  //     US-based companies to create its products. However, it doesn't apply to
  //     consumers who already own Huawei products. Consumers can still buy new
  //     Huawei products. The US government has also banned US government agencies
  //     from using Huawei smartphones. The US government has also pressured AT&T,
  //     Verizon, and T-Mobile from selling Huawei phones.) According to the
  //     products HTSUS code of the product code, and the CBS ruling docs,
  //     what do you know about these prodct relate to the customs legal compliance? `;

  const prompt = `depends on each row of product name, write a pharagh for each product, tell me if the product can import to the US?`;

  useEffect(() => {
    console.log("csvResponce", csvResponce);
    if (csvResponce && fid === "legal-compliance") {
      dispatch(
        legalComplianceTempThunk({
          source: "google",
          data: `${csvResponce}`,
          prompt,
        })
      );
    }
  }, [csvResponce, fid]);

  useEffect(() => {
    if (harmonizedCode.result) {
      dispatch(
        legalComplianceTempThunk({
          source: "google",
          data: `${harmonizedCode.result}`,
          prompt,
        })
      );
    }
  }, [harmonizedCode.result]);

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSendMessageClick = () => {
    dispatch(
      legalComplianceTempThunk({
        source: "google",
        prompt: message,
      })
    );
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    dispatch(
      legalComplianceTempThunk({
        source: "google",
        prompt: message,
      })
    );
  };

  return (
    <>
      <h5>Legal Compliance AI analysis of CBP database and Duty</h5>
      <p>
        U.S. Customs and Border Protection (CBP) issues binding advance rulings
        and other legal decisions in connection with the importation of
        merchandise into the United States. Advance rulings provide the
        international trade community with a transparent and efficient means of
        understanding how CBP will treat a prospective import or carrier
        transaction.
      </p>
      <p>
        For example, a ruling letter may address the tariff classification or
        appraised value of merchandise, the liquidation of an entry, or the
        exclusion of merchandise from entry. As such, ruling letters facilitate
        trade by enabling companies to make business decisions that are
        dependent on how their goods will be treated on importation.
      </p>

      {/* <div className="p-3 pt-0">
        <FileAttachment fid="legal-compliance" />
        <p className="form-text">Attach the file with equipment list.</p>
      </div> */}

      {/* <form className="input-group mb-3" onSubmit={handleFormSubmit}>
        <input
          className="form-control"
          placeholder="ask ruling 8501-8525, eg 'tell me about TACO Comfort Solutions'"
          onChange={handleMessageChange}
        />
        <button
          className="btn btn-outline-secondary"
          type="button"
          id="button-addon2"
          onClick={handleSendMessageClick}
        >
          Send
        </button>
      </form> */}

      {(fileStatus === "pending" || legalCompliance.status === "pending") && (
        <button className="btn btn-primary mb-3" type="button" disabled>
          <span
            className="spinner-grow spinner-grow-sm me-2"
            aria-hidden="true"
          ></span>
          <span role="status">Processing...</span>
        </button>
      )}
      <CustomsTudyEstimationTable />
      <LegalComplianceContent />

      {/* {result && legalComplianceStatus !== "pending" && (
        <div className="bg-light text-dark mb-3 p-3 rounded">
          <MarkdownTableWrapper>
            <ReactMarkdown remarkPlugins={[remarkGfm]}>
              {result.prompt}
            </ReactMarkdown>
          </MarkdownTableWrapper>
        </div>
      )} */}
      {/* {result && legalComplianceStatus !== "pending" && (
        <div className="bg-secondary text-light mb-3 p-3 rounded">
          <MarkdownTableWrapper>
            <ReactMarkdown remarkPlugins={[remarkGfm]}>{result}</ReactMarkdown>
          </MarkdownTableWrapper>
        </div>
      )} */}
      <p>POC: Richard, Jay, ZY</p>
      {/* <p>
        Base Model: Google chat-bison-001, Fine-tuned for multi-turn
        conversation use cases. Training data: Up to Feb 2023.
      </p> */}
      <p className="border border-success text-success rounded-3 p-1">
        Status: Demo Ready
      </p>
    </>
  );
}
