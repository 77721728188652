import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  dutyCellThunk,
  legalComplianceTempThunk,
} from "../../../../store/TradinAIThunks";
import Modal from "react-bootstrap/Modal";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

export default function CustomsTudyEstimationTable() {
  const { legalCompliance, harmonizedCode } = useSelector(
    (state) => state.tradinAI
  );
  const { result, status: legalComplianceStatus } = legalCompliance;

  return (
    <>
      {Object.keys(result).length > 0 && (
        <div>
          <table className="table table-bordered caption-top">
            <caption>
              Legal Compliance and Customs Duty Estimation table
            </caption>
            <thead>
              <tr>
                <th>Product Name</th>
                <th>if import allow?</th>
                <th>Duty</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(result).map((key) => (
                <tr key={key}>
                  <td>{key}</td>
                  <td>
                    <ImportConditionCheckCell content={result[key]} />
                  </td>
                  <td>
                    <DutyCell productDeviceName={key} content={result[key]} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
}

function ImportConditionCheckCell({ content }) {
  const [show, setShow] = useState(false);
  const regex = /yes/i;
  const match = regex.exec(content);

  const handleClose = () => setShow(false);

  return (
    <>
      {match ? (
        <strong
          className="text-success"
          onClick={() => setShow(true)}
          style={{ cursor: "pointer" }}
        >
          yes
        </strong>
      ) : (
        <strong
          className="text-danger"
          onClick={() => setShow(true)}
          style={{ cursor: "pointer" }}
        >
          no
        </strong>
      )}
      <ImportConditionCheckCellModal
        content={content}
        show={show}
        handleClose={handleClose}
      />
    </>
  );
}

function ImportConditionCheckCellModal({ content, show, handleClose }) {
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton></Modal.Header>
      <div className="modal-body">
        <ReactMarkdown>{content}</ReactMarkdown>
      </div>
    </Modal>
  );
}

function DutyCell({ productDeviceName, content }) {
  const regex = /yes/i;
  const match = regex.exec(content);
  const dispatch = useDispatch();
  const { dutyCell } = useSelector((state) => state.tradinAI);
  const prompt =
    "Try your best to estimate the duty rate for importing this product to the US? display the rate result only, eg '23%'";

  useEffect(() => {
    if (productDeviceName) {
      dispatch(
        dutyCellThunk({
          source: "google",
          productDeviceName,
          prompt,
        })
      );
    }
  }, []);

  return <>{match ? dutyCell[productDeviceName].result : "N/A"}</>;
}
