import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { setAttachment } from "../../store/FileSlice";

const FileAttachment = ({ fid }) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState(null);

  const handleDrop = (e) => {
    console.log("handleDrop");
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    setFile(file);
    dispatch(setAttachment({ attachment: file, fid }));
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  return (
    <div
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      style={{ border: "dashed", padding: "10px" }}
    >
      {file ? (
        <div>
          <p>File Name: {file.name}</p>
          <p>File Type: {file.type}</p>
          <p>File Size: {file.size} bytes</p>
        </div>
      ) : (
        <p>Drag and drop a file here</p>
      )}
    </div>
  );
};

export default FileAttachment;
