import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { genPDF2textURL } from "../services/fileService";
import GooglePalmService from "../services/googlePalmService";

// @todo, need process file
// @reference https://ocr.space/OCRAPI
export const processOCRImage = createAsyncThunk(
  "file/processOCRImage",
  async (_payload) => {
    const rsp = await fetch("https://ocr.deno.dev/ocr").then((res) =>
      res.json()
    );
    return rsp;
  }
);

const readerProcess = (payload) =>
  new Promise((resolve, rejected) => {
    const reader = new FileReader();
    reader.readAsText(payload);
    setTimeout(() => {
      rejected("read file failed");
    }, 1000);
    reader.onload = (e) => {
      const text = e.target.result;
      console.log(text);
      resolve(text);
    };
  });

export const processCSV = createAsyncThunk(
  "file/processCSV",
  async (payload) => {
    const respond = await readerProcess(payload);
    return respond;
  }
);

export const processManufacturesPDF = createAsyncThunk(
  "file/processManufacturesPDF",
  async (payload) => {
    console.log("processManufacturesPDF", payload);
    const respond = await genPDF2textURL(payload);
    return respond;
  }
);

const processAIPrompt = async (payload) => {
  console.log("processAIPrompt called");
  const { data, prompt, source } = payload;
  let responce;
  switch (source) {
    case "google":
      responce = await GooglePalmService.generateText({
        model: "gemini-1.5-flash-latest:generateContent",
        version: "v1beta",
        text: `for data: ###${data}### ${prompt}`,
      });
      break;
    default:
      responce = await fetch(
        `https://finai-server.deno.dev/fin-rider/chatgpt`,
        {
          method: "POST",
          body: JSON.stringify({
            messages: [
              { role: "system", content: data },
              {
                role: "user",
                content: prompt,
              },
            ],
          }),
        }
      ).then((res) => res.json());
  }

  return {
    source,
    responce,
  };
};

export const processAIManufacturesPrompt = createAsyncThunk(
  "file/preocessAIManufacturesPrompt",
  processAIPrompt
);

export const processAIProductsDutyPrompt = createAsyncThunk(
  "file/processAICSVPrompt",
  processAIPrompt
);

const FileSlice = createSlice({
  name: "file",
  initialState: {
    status: "idle",
    attachment: null,
    ocrResponce: null,
    csvResponce: null,
    manufactureResponce: {
      status: "idle",
      aiResponce: null,
      error: null,
      content: null,
    },
    aiProcessManufacturesEntityList: {
      status: "idle",
      messages: [],
      aiResponce: null,
    },
    aiProcessProductsDuty: {
      status: "idle",
      messages: [],
      aiResponce: null,
    },
  },
  reducers: {
    processCSV: (state, action) => {
      state.status = "pending";
    },
    setAttachment: (state, action) => {
      console.log("setAttachment start", action.payload);
      // const { fid, attachment } = action.payload;
      state.attachment = action.payload.attachment;
      state.fid = action.payload.fid;
      console.log("setAttachment end", state.attachment);
    },
    setManufactureResponce: (state, action) => {
      state.manufactureResponce.content = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(processOCRImage.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(processOCRImage.fulfilled, (state, action) => {
      state.status = "idle";
      state.ocrResponce = action.payload;
    });
    builder.addCase(processOCRImage.rejected, (state, action) => {
      state.error = action.payload;
      console.error("processOCRImage.rejected", action.payload);
    });
    builder.addCase(processCSV.pending, (state) => {
      state.status = "pending";
    });
    builder.addCase(processCSV.fulfilled, (state, action) => {
      state.status = "idle";
      state.csvResponce = action.payload;
    });
    builder.addCase(processCSV.rejected, (state, action) => {
      state.error = action.payload;
      console.error("processCSV.rejected", action.payload);
    });
    builder.addCase(processAIProductsDutyPrompt.pending, (state) => {
      state.aiProcessProductsDuty.status = "pending";
      state.aiProcessProductsDuty.aiResponce = null;
    });
    builder.addCase(processAIProductsDutyPrompt.fulfilled, (state, action) => {
      console.info(
        "processAICSVPrompt.fulfilled action.payload",
        action.payload
      );
      state.aiProcessProductsDuty.status = "idle";
      const { source, responce } = action.payload;
      switch (source) {
        case "google":
          state.aiProcessProductsDuty.aiResponce =
            responce.candidates[0].output;
          break;
        default:
          // open ai
          state.aiProcessProductsDuty.aiResponce =
            action.payload.rsp.choices[0].message.content;
      }
    });
    builder.addCase(processAIProductsDutyPrompt.rejected, (state, action) => {
      state.aiProcessProductsDuty.error = action.payload;
      console.error("processAICSVPrompt.rejected", action.payload);
    });
    builder.addCase(processManufacturesPDF.pending, (state) => {
      state.manufactureResponce.status = "pending";
    });
    builder.addCase(processManufacturesPDF.fulfilled, (state, action) => {
      state.manufactureResponce.status = "idle";
      state.manufactureResponce.content = action.payload;
    });
    builder.addCase(processManufacturesPDF.rejected, (state, action) => {
      state.manufactureResponce.error = action.payload;
      state.manufactureResponce.status = "idle";
      console.error("processManufacturesPDF.rejected", action.payload);
    });
    builder.addCase(processAIManufacturesPrompt.pending, (state) => {
      console.log("processAIManufacturesPrompt.pending");
      state.aiProcessManufacturesEntityList.status = "pending";
      state.aiProcessManufacturesEntityList.aiResponce = null;
    });
    builder.addCase(processAIManufacturesPrompt.fulfilled, (state, action) => {
      console.info(
        "processAIManufacturesPrompt.fulfilled action.payload",
        action.payload
      );
      const { source, responce } = action.payload;
      state.aiProcessManufacturesEntityList.status = "idle";

      switch (source) {
        case "google":
          state.aiProcessManufacturesEntityList.aiResponce =
            responce.candidates[0].output;
          break;
        default:
          // open ai
          state.aiProcessManufacturesEntityList.aiResponce =
            action.payload.rsp.choices[0].message.content;
      }
    });
    builder.addCase(processAIManufacturesPrompt.rejected, (state, action) => {
      state.aiProcessManufacturesEntityList.error = action.payload;
      console.error("processAIManufacturesPrompt.rejected", action.payload);
    });
  },
});

export const { setAttachment, manufactureResponce, setManufactureResponce } =
  FileSlice.actions;

export default FileSlice;
