import { Navigate, createHashRouter, redirect } from "react-router-dom";
import InternationalTrading from "./pages/InternationalTrading/TradingCompliance";
import PromptEngineering from "./pages/PromptEngineering";
import { useEffect } from "react";

const router = createHashRouter([
  {
    path: "/",
    element: <InternationalTrading />,
  },
  {
    path: "/prompt-engineering",
    element: <PromptEngineering />,
    exact: true,
  },
  {
    path: "/prompt-engineering/openai/deprecation",
    element: <Redirect href="https://platform.openai.com/docs/deprecations" />,
  },
]);

function Redirect({ href }) {
  useEffect(() => {
    window.location.href = href;
  }, []);
  return null;
}

export default router;
