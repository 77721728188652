export default class GooglePalmService {
  static async generateText({
    text,
    version = "v1beta2",
    model = "text-bison-001:generateText",
  }) {
    console.log("google palm service generateText called", text);
    const data =
      model === "gemini-1.5-flash-latest:generateContent"
        ? { contents: [{ parts: [{ text }] }] }
        : {
            prompt: { text },
          };
    const rsp = await fetch(
      `https://finai-server.deno.dev/generativelanguage/${version}/models/${model}`,
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Length": `${JSON.stringify(data).length}`,
        },
      }
    ).then((rsp) => rsp.json());

    console.log("rsp.json()", rsp);
    return rsp;
  }

  static async generateMessage({
    messages,
    version = "v1beta2",
    model = "chat-bison-001:generateMessage",
  }) {
    console.log("google palm service generateMessage called", messages);
    let data;
    if (model.includes("gemini")) {
      const parts = messages.map((m) => ({ text: m.content }));
      data = { contents: [{ parts }] };
    } else {
      data = {
        prompt: { messages },
      };
    }
    const rsp = await fetch(
      `https://finai-server.deno.dev/generativelanguage/${version}/models/${model}`,
      {
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((rsp) => rsp.json());

    console.log("rsp.json()", rsp);
    return rsp;
  }

  static async embedText({ text }) {
    console.log("google palm service embedText called", text);
    const rsp = await fetch(
      `https://finai-server.deno.dev/generativelanguage/v1beta2/models/embedding-gecko-001:embedText`,
      {
        method: "POST",
        body: JSON.stringify({
          text,
        }),
      }
    ).then((rsp) => rsp.json());

    console.log("rsp.json()", rsp);
    return rsp;
  }
}
