import { useEffect } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useDispatch, useSelector } from "react-redux";
import remarkGfm from "remark-gfm";
import { legalComplianceTempThunk } from "../../../../store/TradinAIThunks";

export default function LegalComplianceContent() {
  const dispatch = useDispatch();
  const {
    attachment,
    fid,
    status: fileStatus,
    csvResponce,
    manufactureResponce,
    aiProcessManufacturesEntityList,
    aiProcessProductsDuty,
  } = useSelector((state) => state.file);
  const { productData } = useSelector((state) => state.tradinAI);

  return (
    <div>
      {productData &&
        productData.map((product) => (
          <LegalComplianceItem key={product["Device Name"]} product={product} />
        ))}
    </div>
  );
}

function LegalComplianceItem({ product }) {
  const dispatch = useDispatch();
  const { legalCompliance } = useSelector((state) => state.tradinAI);
  const { result, status: legalComplianceStatus } = legalCompliance || {};
  const prompt = `provide me an explaination whether ${product["Device Name"]} can be imported to the US? 
                  List out detail factors and explain whether ${product["Device Name"]} is complied with those factors. 
                  Don't provide response containing further research.`;

  useEffect(() => {
    if (product) {
      dispatch(
        legalComplianceTempThunk({
          source: "google",
          product,
          data: `${product}`,
          prompt,
        })
      );
    }
  }, [product]);

  if (!result || !result[product["Device Name"]]) return null;
  return (
    <div style={{ display: "none" }}>
      {legalComplianceStatus === "pending" && <div>loading...</div>}
      <div className="row">
        <div className="col-3">{product["Device Name"]}</div>
        <div className="col-9">
          <ReactMarkdown remarkPlugins={[remarkGfm]}>
            {result[product["Device Name"]]}
          </ReactMarkdown>
        </div>
      </div>
    </div>
  );
}
