export default class Chat {
  constructor() {
    this.completions = new ChatCompletions();
  }
}

class ChatCompletions {
  async create({ messages, model }) {
    const rsp = await fetch(
      "https://finai-server.deno.dev/openai/v1/chat/completion",
      {
        method: "POST",
        body: JSON.stringify({ messages, model }),
      }
    ).then((rsp) => rsp.json());
    return rsp;
  }
}
