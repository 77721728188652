export default class TextCompletions {
  async create({
    model,
    prompt,
    suffix = null,
    max_tokens = 16,
    temperature = 1,
    top_p = 1,
    n = 1,
    stream = false,
    logprobs = null,
    echo = false,
    stop = null,
    presence_penalty = 0,
    frequency_penalty = 0,
    best_of = 1,
    logit_bias = null,
    user,
  }) {
    const rsp = await fetch(
      "https://finai-server.deno.dev/openai/v1/completions",
      {
        method: "POST",
        body: JSON.stringify({
          model,
          prompt,
          suffix,
          max_tokens,
          temperature,
          top_p,
          n,
          stream,
          logprobs,
          echo,
          stop,
          presence_penalty,
          frequency_penalty,
          best_of,
          logit_bias,
          user,
        }),
      }
    ).then((rsp) => rsp.json());
    return rsp;
  }
}
