import { createAsyncThunk } from "@reduxjs/toolkit";
import GooglePalmService from "../services/googlePalmService";

export const sanctionCheckThunk = createAsyncThunk(
  "tradingAI/sanctionCheckThunk",
  async (payload) => {
    console.log("sanctionCheckThunk called");
    const { entityList, prompt } = payload;
    await GooglePalmService.generateText({ text: prompt });
    const respond = await fetch(
      `https://finai-server.deno.dev/fin-rider/chatgpt`,
      {
        method: "POST",
        body: JSON.stringify({
          model: "GPT-4o",
          messages: [
            { role: "system", content: entityList },
            {
              role: "user",
              content:
                prompt ||
                "checking the validity of the transaction for US customs. Huawei should failed.",
            },
          ],
        }),
      }
    ).then((res) => res.json());
    return respond;
  }
);

export const harmonizedCodeThunk = createAsyncThunk(
  "tradingAI/harmonizedCodeThunk",
  async (payload) => {
    const { data, prompt, source } = payload;
    let responce;
    switch (source) {
      case "google":
        responce = await GooglePalmService.generateText({
          text: `for data: ###${data}### ${prompt}`,
        });
        break;
      default:
        responce = await fetch(
          `https://finai-server.deno.dev/fin-rider/chatgpt`,
          {
            method: "POST",
            body: JSON.stringify({
              model: "GPT-4o",
              messages: [
                { role: "system", content: data },
                {
                  role: "user",
                  content:
                    prompt ||
                    "predict the harmonized code based on product description",
                },
              ],
            }),
          }
        ).then((res) => res.json());
    }
    return {
      source,
      responce,
    };
  }
);

export const legalComplianceTempThunk = createAsyncThunk(
  "tradingAI/legalComplianceTempThunk",
  async (payload) => {
    const { data, prompt, source, product } = payload;
    console.log(
      "tradingAI/legalComplianceTempThunk data, prompt, source, product",
      data,
      prompt,
      source,
      product
    );
    let responce;

    switch (source) {
      case "google":
        responce = await GooglePalmService.generateMessage({
          messages: [{ content: `${prompt}` }],
          model: "gemini-1.5-flash-latest:generateContent",
          version: "v1beta",
        });
        break;
      default:
        responce = await fetch(
          `https://finai-server.deno.dev/fin-rider/chatgpt`,
          {
            method: "POST",
            body: JSON.stringify({
              model: "GPT-4o",
              messages: [
                { role: "system", content: data },
                {
                  role: "user",
                  content: prompt || "tell me the related ruling content",
                },
              ],
            }),
          }
        ).then((res) => res.json());
    }

    return {
      product,
      source,
      responce,
    };
  }
);

export const legalComplianceThunk = createAsyncThunk(
  "tradingAI/legalComplianceThunk",
  async (payload) => {
    const { productDescription, prompt } = payload;
    const respond = await fetch(
      `https://finai-server.deno.dev/fin-rider/chatgpt`,
      {
        method: "POST",
        body: JSON.stringify({
          model: "GPT-4o",
          messages: [
            { role: "system", content: productDescription },
            {
              role: "user",
              content:
                prompt ||
                "checking the legal compliance based on the CBP database",
            },
          ],
        }),
      }
    ).then((res) => res.json());
    return respond;
  }
);

export const dutyEstimationThunk = createAsyncThunk(
  "tradinAI/dutyEstimationThunk",
  async (payload) => {
    const { data, prompt, source, product } = payload;
    let responce;
    switch (source) {
      case "google":
        responce = await GooglePalmService.generateText({
          text: `for product ${product["Device Name"]} ${prompt}`,
        });
        break;
      default:
        responce = await fetch(
          `https://finai-server.deno.dev/fin-rider/chatgpt`,
          {
            method: "POST",
            body: JSON.stringify({
              model: "GPT-4o",
              messages: [
                { role: "system", content: data },
                {
                  role: "user",
                  content: prompt || "tell me the related ruling content",
                },
              ],
            }),
          }
        ).then((res) => res.json());
    }
    return {
      source,
      responce,
    };
  }
);

export const dutyCellThunk = createAsyncThunk(
  "tradinAI/dutyCellThunk",
  async (payload) => {
    console.log("(dutyCellThunk) called");
    const { productDeviceName, prompt } = payload;
    const responce = await GooglePalmService.generateText({
      text: `for product ${productDeviceName}, ${prompt}`,
      model: "gemini-1.5-flash-latest:generateContent",
      version: "v1beta",
    });
    return { responce };
  }
);
