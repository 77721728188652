import { useDispatch, useSelector } from "react-redux";
import FileAttachment from "../../components/forms/FileAttachment";
import { useEffect, useState } from "react";
import {
  processCSV,
  processAIProductsDutyPrompt,
  processManufacturesPDF,
  processAIManufacturesPrompt,
  setManufactureResponce,
} from "../../store/FileSlice";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";
import { styled } from "styled-components";
import "./TradingCompliance.css";
import HarmonizedCodeSection from "./TradingCompliance/HarmonizedCodeSection";
import LegalComplianceSection from "./TradingCompliance/LegalComplianceSection/LegalComplienceSection";
import CustomsDutyEstimation from "./TradingCompliance/CustomsDutyEstimation/CustomsDutyEstimation";

export const MarkdownTableWrapper = styled.div`
  table {
    margin: 8px;
  }
  table tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
  }

  table tr:last-child td:last-child {
    border-bottom-right-radius: 8px;
  }
  td,
  th {
    border: 1px solid #ccc;
    padding: 8px;
  }
`;

export default function InternationalTrading() {
  const dispatch = useDispatch();
  const [pdfURL, setPDFURL] = useState(
    "https://dotku.github.io/fin-rider/assets/pdfs/sampleManufactures.pdf"
  );
  const {
    attachment,
    fid,
    status,
    csvResponce,
    manufactureResponce,
    aiProcessManufacturesEntityList,
    aiProcessProductsDuty,
  } = useSelector((state) => state.file);
  const [suppliersList, setSuppliersList] = useState(
    `
  Samsung Electronics Co. Ltd.
Apple Inc.	
Sony Corporation	
LG Electronics Inc.	
Panasonic Corporation	
Toshiba Corporation	
Huawei Technologies Co. Ltd.
Lenovo Group Limited	
Dell Technologies Inc.	
HP Inc.`.trim()
  );

  useEffect(() => {
    if (attachment) {
      console.log("attachment");
      console.log(attachment);
      dispatch(processCSV(attachment));
    }
  }, [attachment]);

  useEffect(() => {
    if (csvResponce && fid === "customs-duty") {
      dispatch(
        processAIProductsDutyPrompt({
          source: "google",
          data: `tax data: "origin, tax\n China, 30%\n Vietname, 10%". 
                product csv: ${csvResponce}`,
          prompt: `gen US customs tax report with the items in the system 
                content in percentage with markdown table. Don't display any 
                description or foot notes.`,
        })
      );
    }
  }, [csvResponce, fid]);

  useEffect(() => {
    if (manufactureResponce.content) {
      dispatch(
        processAIManufacturesPrompt({
          source: "google",
          data: `${manufactureResponce.content.body}`,
          prompt: `Try your best, predict and generate a table with the 
            following columns: "company name, country, status", status for 
            entity sanction checking with result of "pass", "limited" or "failed".
            Huawei would be failed. Lenovo would be limited.`,
        })
      );
    }
  }, [manufactureResponce.content]);

  const handlePDFExtractClick = () => {
    dispatch(setManufactureResponce({ body: suppliersList }));
  };

  return (
    <div className="container py-5" id="TradingCompliance">
      <h2>
        Mayflower: International Trading AI Service{" "}
        <span className="badge text-bg-primary">
          v{process.env.REACT_APP_VERSION}
        </span>
      </h2>
      <p>by Tess, Richard Ma, Weijing Jay Lin, Yun, ZY, George, Claire</p>
      <p>
        This is a demo of trading compliance page. With Tradin AI Service, the
        customer would able to submit their doc through the AI system and got
        efficent respond. And improve their paper process performance.
      </p>
      <h3>Trading Compliance Process</h3>
      <p>
        For customers here is the main steps and feature they need in order to
        acomplish their tasks
      </p>
      <section className="m-5">
        <h5>Entity Sanction Check</h5>
        <p>This could be easily applied by database checking.</p>
        <ol>
          <li>The customer upload their docs with company information</li>
          <li>
            Generate the compliace result as:{" "}
            <strong className="text-success">[pass]</strong>,{" "}
            <strong className="text-success">[limited]</strong> or{" "}
            <strong className="text-danger">[failed]</strong>.
          </li>
        </ol>
        <div className="mb-3">
          <div className="mb-1">
            <textarea
              className="form-control"
              value={suppliersList}
              rows={10}
              onChange={(e) => setSuppliersList(e.target.value)}
            />
          </div>
          <div className="text-end">
            <button
              className="btn btn-outline-secondary"
              type="button"
              id="button-addon"
              onClick={handlePDFExtractClick}
              disabled={manufactureResponce.status === "pending"}
            >
              Check
            </button>
          </div>
        </div>
        {/* <div className="mb-3">
          <div className="input-group">
            <input
              className="form-control"
              aria-describedby="button-addon"
              type="text"
              value={pdfURL}
              onChange={(e) => {
                setPDFURL(e.target.value);
              }}
            />
            <span
              className="btn btn-outline-secondary"
              type="button"
              id="button-addon"
              onClick={handlePDFExtractClick}
              disabled={manufactureResponce.status === "pending"}
            >
              Extract
            </span>
          </div>
          <div className="form-text">
            sample file:
            https://dotku.github.io/fin-rider/assets/pdfs/sampleManufactures.pdf
          </div>
        </div> */}
        {(aiProcessManufacturesEntityList?.status === "pending" ||
          processAIManufacturesPrompt.status === "pedding") && (
          <button className="btn btn-primary mb-3" type="button" disabled>
            <span
              className="spinner-grow spinner-grow-sm me-2"
              aria-hidden="true"
            ></span>
            <span role="status">Processing...</span>
          </button>
        )}
        {aiProcessManufacturesEntityList?.aiResponce && (
          <div className="bg-light text-dark mb-3 p-3 rounded">
            <MarkdownTableWrapper>
              <ReactMarkdown remarkPlugins={[remarkGfm]}>
                {aiProcessManufacturesEntityList?.aiResponce}
              </ReactMarkdown>
            </MarkdownTableWrapper>
          </div>
        )}
        <p className="border p-2 py-1 rounded-3 text-dark bg-warning">
          Status: 2nd version is in progress. Yun will use PDF reader to extract
          the companies information. Due to the PDF content could be complex to
          parse.
        </p>
        <p>POC: Richard Ma, Jay Lin, Yun</p>
        {/* <p>
          Based Model: Google AI/text-bison, text-bison is a light weight, fast
          and cost efficent AI model provided by Google. Training data: Up to
          Feb 2023.
        </p> */}
        <hr />
        <HarmonizedCodeSection />
        <hr />
        <LegalComplianceSection />
        {/* <hr />
        <CustomsDutyEstimation /> */}
      </section>
    </div>
  );
}
