import { useDispatch, useSelector } from "react-redux";
import FileAttachment from "../../../components/forms/FileAttachment";
import { useEffect } from "react";
import {
  processAIProductsDutyPrompt,
  processCSV,
} from "../../../store/FileSlice";
import {
  // processProductDataThunk,
  setProductData,
} from "../../../store/TradinAISlice";
import { MarkdownTableWrapper } from "../TradingCompliance";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";
import { harmonizedCodeThunk } from "../../../store/TradinAIThunks";

export default function HarmonizedCodeSection() {
  const dispatch = useDispatch();
  const {
    attachment,
    fid,
    status: fileStatus,
    csvResponce,
    manufactureResponce,
    aiProcessManufacturesEntityList,
    aiProcessProductsDuty,
  } = useSelector((state) => state.file);
  const { harmonizedCode } = useSelector((state) => state.tradinAI);
  const { result, status: harmonizedCodeStatus } = harmonizedCode || {};

  // useEffect(() => {
  //   if (attachment) {
  //     console.log("attachment");
  //     console.log(attachment);
  //     dispatch(processCSV(attachment));
  //   }
  // }, [attachment]);

  useEffect(() => {
    console.log("csvResponce", csvResponce);
    if (csvResponce && fid === "harmonized-code") {
      dispatch(
        harmonizedCodeThunk({
          data: `${csvResponce}`,
          prompt: `try your best, predict, gen US HTSUS heading result 
          markdown table with column "product name", "company name", 
          "manufacture origin", "HTSUS Name" and "HTSUS" columns by using 
          the items in the system. HTSUS use 10 digit format, eg 8471.30.01.00.`,
        })
      );
      console.log(
        "dispatch(processProductDataThunk(csvResponce))",
        csvResponce
      );
      dispatch(setProductData(csvResponce));
    }
  }, [csvResponce, fid]);

  return (
    <section>
      <h5>Harmonized Code/HTSUS heading finding</h5>
      <figure>
        <blockquote className="blockquote text-secondary">
          "HTSUS" stands for Harmonized Tariff Schedule of the United States
          Code and they are quite similar to an HS (Harmonized System) code. The
          primary difference between HTSUS codes and HS codes are that HTSUS
          codes are specific to the United States of America.
        </blockquote>
        <figcaption className="blockquote-footer">
          <a href="https://traderiskguaranty.com/trgpeak/hts-codes-hs-codes/">
            <cite title="HTSUS Codes and HS Codes: What’s the Difference?">
              TRG
            </cite>
          </a>
        </figcaption>
      </figure>
      <div className="p-3 pt-0">
        <FileAttachment fid="harmonized-code" />
        <p className="form-text">Attach the file with equipment list.</p>
      </div>
      {(fileStatus === "pending" || harmonizedCode.status === "pending") && (
        <button className="btn btn-primary mb-3" type="button" disabled>
          <span
            className="spinner-grow spinner-grow-sm me-2"
            aria-hidden="true"
          ></span>
          <span role="status">Processing...</span>
        </button>
      )}
      {result && (
        <div className="bg-light text-dark mb-3 p-3 rounded">
          <MarkdownTableWrapper>
            <ReactMarkdown remarkPlugins={[remarkGfm]}>{result}</ReactMarkdown>
          </MarkdownTableWrapper>
        </div>
      )}

      <p>POC: Jay, Yun</p>
      {/* <p>Base Model: OpenAI GPT-4</p> */}
      <p>
        We might need collect all product information, and based on its
        functionality to predict the Harmonized Traffit code.
      </p>
      <p className="border border-success p-1 px-2 rounded-3 text-success">
        Status: Demo Ready, might have potential accuracy issue. It won't able
        to process new type of product so well, eg drone.
      </p>
    </section>
  );
}
