import googleModels from "./data/google-models.json";
import openAIModels from "./data/openai-models.json";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addProviderMap, id2name, processGooglePrompt } from "./utils";
import OpenAIService from "../../services/OpenAIService";

export const sendPrompt = createAsyncThunk(
  "promptEngineering/sendPrompt",
  async (payload) => {
    console.log("promptEngineering/sendPrompt called");
    const { prompt, model } = payload;
    const { provider } = model;
    switch (provider) {
      case "google": {
        const rsp = await processGooglePrompt({ prompt, model });
        return rsp;
      }
      case "openai": {
        const rsp = await OpenAIService.run({ prompt, model });
        return rsp;
      }
      default:
        console.warn("unkown provider, fall by OpenAI");
        const rsp = await OpenAIService.run({ prompt, model });
        return rsp;
    }
  }
);

const PromptEngineeringSlice = createSlice({
  name: "promptEngineering",
  initialState: {
    sendPromptResponce: {
      status: "idle",
      result: null,
    },
    currentModel: null,
    models: [
      ...googleModels.models.map(addProviderMap("google")),
      ...openAIModels.data.map(id2name),
    ],
  },
  reducers: {
    updateCurrentModel: (state, action) => {
      state.currentModel = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendPrompt.pending, (state) => {
      state.sendPromptResponce.status = "pending";
    });
    builder.addCase(sendPrompt.fulfilled, (state, action) => {
      console.info("sendPrompt.fulfilled action", action);
      const { error, candidates, message, embedding, filters } = action.payload;
      state.sendPromptResponce.response = action.payload;
      if (error || action.payload instanceof Error) {
        state.sendPromptResponce.status = "failed";
        state.sendPromptResponce.result = error?.message || message;
        return;
      }

      if (embedding) {
        state.sendPromptResponce.status = "success";
        state.sendPromptResponce.result = embedding;
        return;
      }

      if (!candidates) {
        state.sendPromptResponce.status = "failed";
        state.sendPromptResponce.result = "no candidates return";
        if (filters) {
          console.warn("sendPromptResponce failed due to filters", filters);
        }
        return;
      }

      const { content, output } = candidates[0];

      state.sendPromptResponce.status = "success";
      state.sendPromptResponce.result = content || output || "no result";
    });
    builder.addCase(sendPrompt.rejected, (state, action) => {
      console.info("sendPrompt.rejected action", action);
      state.sendPromptResponce.status = "rejected";
      state.sendPromptResponce.result = action.error;
    });
  },
});

export const { updateCurrentModel } = PromptEngineeringSlice.actions;

export default PromptEngineeringSlice;
