import { useDispatch, useSelector } from "react-redux";
import {
  sendPrompt,
  updateCurrentModel,
} from "../../store/PromptEngingeeringSlice/PromptEngineeringSlice";
import { useEffect, useState } from "react";
import { MarkdownTableWrapper } from "../InternationalTrading/TradingCompliance";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import remarkGfm from "remark-gfm";
import { DeprecatedModelWarningCard } from "./Components/DeprecatedModelWarningCard";
import { ModelListItem } from "./Components/ModelListItem";

export default function PromptEngineering() {
  const dispatch = useDispatch();
  const { models, currentModel, sendPromptResponce } = useSelector(
    (state) => state.promptEngineering
  );
  const { status, result } = sendPromptResponce;
  const [prompt, setPrompt] = useState("");

  useEffect(() => {
    dispatch(updateCurrentModel(models[0]));
  }, []);

  const handlePromptSubmit = (e) => {
    e.preventDefault();
    console.log("handlePromptSubmit");
    dispatch(
      sendPrompt({
        prompt,
        model: currentModel,
        provider: currentModel.owned_by || "google",
      })
    );
  };

  return (
    <div>
      <div className="row">
        <div className="col-3">
          <div className="container">
            <h1>Prompt Engineering</h1>
            <div
              style={{ maxHeight: "calc(100vh - 160px)", overflowY: "auto" }}
            >
              <div className="list-group list-group-flush">
                {models.map((model) => (
                  <ModelListItem {...model} key={model.name} />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="container">
            <form className="mt-2 mb-3" onSubmit={handlePromptSubmit}>
              <textarea
                className="form-control"
                placeholder="What prompt do you want to send?"
                id="exampleFormControlTextarea1"
                rows="3"
                onChange={(e) => setPrompt(e.target.value)}
              ></textarea>
              <div className="text-center">
                <button type="submit" className="btn btn-primary mt-3">
                  Send
                </button>
              </div>
            </form>
            {status === "pending" && (
              <div className="spinner-grow spinner-grow-sm" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            )}
            {(status === "failed" || status === "rejected") && (
              <div className="text-danger">{result}</div>
            )}
            {status === "success" && <ResponseResult result={result} />}
          </div>
        </div>
        <div className="col-3">
          <div className="container text-break">
            <section>
              <label>Model Content</label>
              <div className="form-text">{JSON.stringify(currentModel)}</div>
              {currentModel && (
                <DeprecatedModelWarningCard model={currentModel} />
              )}
            </section>
            <section>
              <label>Todo</label>
              <ul>
                <li>Support chat expierience</li>
              </ul>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
}

function ResponseResult({ result }) {
  if (result.value) {
    return (
      <div>
        {result.value.map((item, idx) => (
          <div key={idx}>{item}</div>
        ))}
      </div>
    );
  }
  return (
    <MarkdownTableWrapper>
      <ReactMarkdown remarkPlugins={[remarkGfm]}>{result}</ReactMarkdown>
    </MarkdownTableWrapper>
  );
}
